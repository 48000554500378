<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('td-create-action',205)">
        <ValidationObserver ref="form" >
            <app-row-left-label :label="trans('td-action-for',205)">
                <app-input v-model="cArgs.action_for" disabled />
            </app-row-left-label>
            
            <app-row-left-label :label="trans('td-title',205)">
                <app-input v-model="cArgs.title" validatorName="Titel" validatorRules="required" mask="lettersE9-65" />
            </app-row-left-label>
            
            <app-row-left-label :label="trans('td-description',205)">
                    <app-memo id="vi-description" rows="5" class="mt-2" v-model="description" />
            </app-row-left-label>

            <app-row-left-label :label="trans('td-owner',205)" >
                    <app-select  v-model="user" :clearable="true" :disable="false" type="getUserSelectorList"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="location" type="getUserLocationsSimple" :disable="false" validatorName="Vestiging" validatorRules="required"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-to',182)">
                    <app-date  id="vi-event_date" v-model="event_date" :disabledDatesPass="1" validatorRules="required|date-check|date-more-check" validatorName="Einddatum"/>
            </app-row-left-label>

        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" pill variant="primary" @click="addIssue" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppRowLeftLabel from '../../@core/components/AppRowLeftLabel.vue';
import moment from "moment";

export default {
    components: {
        ValidationObserver,
        AppRowLeftLabel,
    },

    props: ["show", "result", "args", "return"],

    created() {
        this.setLoggedUser();
         if(this.cArgs.location_name != null){
               this.location = {name: this.cArgs.location_name, value: this.cArgs.id_office_data_location}
            }
    },   

    watch: {
        description: {
            handler: function() {             
                 if(this.description.length == 1){
                     this.description = this.description.toUpperCase();
                 }   
            },
            deep: true,
        },
    },

    computed: {
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        }
    },

    data() {
        return {

            disabled_dates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
                dates: [
                    moment()
                        .subtract(1, "days")
                        .toDate(),
                ],
            },
            loading: false,
            title:"",
            description:"",
            user: null,
            location: null,
            event_date:null,
            parameters:{
                search:"",
                sort_by: "id",
                sort_desc: false,
                args:[],
            },
        };
    },

    methods: {
        addIssue() {
            this.$refs.form.validate().then((result) => {
            if (!result) { 
                return false;
            } else {
                this.loading = true;
                axios
                    .post("issue/add", {
                        id: this.args.id,
                        id_user: this.user ? this.user.value : null,
                        title: this.args.title,
                        event_date: this.event_date,
                        description: this.description,
                        list_type: this.args.list_type,
                        id_office_data_location: this.location.value
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        if(this.args.list_type == "dashboard"){
                            this.cReturn = res.data;
                        }else if(this.cReturn){
                            this.cReturn.issues.list = res.data;
                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        setLoggedUser() {
            var userData = JSON.parse(localStorage.getItem('user'));
            this.user = {name: userData.user_name.trim(), value: userData.id}          
        }

    },
};
</script>

<style scoped>
</style>
