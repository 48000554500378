<template>
    <b-card>
        <app-view-table :title="trans('actions',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters"  :items="items" v-on:onRowClicked="change" :fields="fields" :filterOptions="issues_status_list" :isSearch="false" :isPeriode="true" :customColumns="customColumns" :isFilterOptions="true" class="table-selectable">
            
            <template v-slot:selected="cell">
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                    <div class="app-issues-status-item" v-bind:class="{ 'app-issues-danger': cell.data.item.status == 0, 'app-issues-success': cell.data.item.status == 1}"></div>
                    <b-form-group class="pl-2">
                        <b-form-checkbox v-model="cell.data.item.status" value="1" unchecked-value="0" :key="refreshCheck" @change="setAsDone(cell.data.item.id)" :class="cell.data.item.status == 1 ? 'custom-control-primary checkbox-green' : 'custom-control-primary'" style="margin-top:5px" size="md" :disabled="user_role == 3 && !cell.data.item.can_user_edit"/>
                    </b-form-group>
                </div>
            </template>


            <template v-slot:status="cell">
                <div>
                    {{cell.data.item.status == 0 ? trans('open',175) : trans('td-handled',205)}}
                </div>
            </template>


            <template v-slot:menu-items="cell">
                <b-dropdown-item v-if="cell.data.item.issue_const_target === 'Klanten'" :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.person_id }}">{{trans('td-open-profile',205)}}</b-dropdown-item>
                <b-dropdown-item v-if="cell.data.item.issue_const_target === 'Sollicitant' || cell.data.item.issue_const_target === 'Flexwerkers'" :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.person_id }}">{{trans('td-open-profile',205)}}</b-dropdown-item>
                <b-dropdown-item v-if="cell.data.item.issue_const_target === 'Locatie'" :to="{ name: 'clients-profile-location', params: { id_location_data_main: cell.data.item.person_id }}">{{trans('td-open-profile',205)}}</b-dropdown-item>
                <b-dropdown-item v-if="cell.data.item.issue_const_target === 'Afdeling'" :to="{ name: 'clients-profile-department', params: { id_department_data_main: cell.data.item.person_id }}">{{trans('td-open-profile',205)}}</b-dropdown-item>
                <b-dropdown-item @click="change(cell.data.item)" v-if="user_role < 3 || cell.data.item.can_user_edit">{{trans('td-open-action',205)}}</b-dropdown-item>
                <b-dropdown-item @click="remove(cell.data.item)" v-if="user_role < 3 || cell.data.item.can_user_edit">{{trans('td-delete-action',205)}}</b-dropdown-item>
            </template>

            <template v-slot:buttons>
                <app-button type="primary" v-if="user_role < 3" @click="addIssue()">{{trans('settings-add-issue', 206)}}</app-button>
                <app-button type="secondary-grey" :loading="loading_download" @click="exportXLS">{{trans('td-export-actions', 205)}}</app-button>
            </template>
            
        </app-view-table>

        <issues-change v-if="mIssuesChange.show" :show.sync="mIssuesChange.show" :args="mIssuesChange.args" :return.sync="items" :filters="filters"/>   
        <issues-add v-if="mIssueAdd.show" :show.sync="mIssueAdd.show" :result.sync="mIssueAdd.result" :args.sync="mIssueAdd.args"/>

    </b-card>  
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import IssuesChange from './IssuesChange.vue';
import IssuesAdd from './IssuesAdd.vue';
import moment from "moment";

export default {
    components: {
        IssuesChange,
        IssuesAdd
    },

    created(){
        this.user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
        this.user_id = JSON.parse(localStorage.getItem('user')).id;

    },

    mounted() {
        this.onCreateThis();
    },

    watch: {
        "mIssueAdd.result": {
            handler: function(val) {
                if(this.mIssueAdd.result == 1){
                    this.items.refresh++;
                    this.mIssueAdd.result = 0;
                } 
            }
        }
    },

    data(){
        return{
            loading_list: false,
            items:[],
            mIssuesChange: { show: false, args: {
                                                id: null,
                                                action_for: null,
                                                title: null,
                                                description: null,
                                                status: null,
                                                event_date: null,
                                                id_user: 0,
                                                user_name: null,
                                                type: null,
                                                location_name:null,
                                                location_id:null,
                                                list_type:"",

            }, result: 0, return: null },
            type:1,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {  
                    user_names: {type: 'list',  changed: 0, values: [], function: "getUserSelectorList", parameters: null, widthClass:'app-width-min-250'},
                    issue_const_targets: {type: 'list', changed: 0, values: [], function: "getIssueForTypes", parameters: null, widthClass:'app-width-min-250'},  
                    statuses: {type: 'list', changed: 0, values: [], function: "getActionStatuses", parameters: null, widthClass:'app-width-min-250'},   
                    creators: {type: 'list', changed: 0, values: [], function: "getUserIssuesSelectorList", parameters: null, widthClass:'app-width-min-250'},                                                                            
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
                period_date_from: null,
                period_date_to: null,
                disabledDatesRange: null,
            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", thStyle: { "min-width": "50px", width: "50px" },visible: true  },
                { key: "title", label: this.trans('td-title',205), thStyle: { "min-width": "70px", width: "100%" }, visible: true },
                { key: "user_name", label: this.trans('td-owner',205), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "user_names" },
                { key: "entry_date", label: this.trans('entry-date',182), thStyle: { "min-width": "160px", "max-width": "200px" }, visible: true  },
                { key: "event_date", label: this.trans('date-to',182), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true  },                
                { key: "issue_const_target", label: this.trans('spieces',182), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "issue_const_targets"  },  
                { key: "person_name", label: this.trans('td-action-for',205), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true  },
                { key: "creator_name", label: this.trans('td-creators',205), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "creators" },                 
                { key: "status", label: this.trans('status',3), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: false, filtering: true, filtering_name: "statuses" },  
               
            ],
            issues_status_list: [
                { value: 1, name: this.trans('td-my-open-actions',205) },
                { value: 2, name: this.trans('td-my-closed-actions',205) },
                { value: -1, name: ""},
                { value: 3, name: this.trans('td-open-my-locations',205) },
                { value: 4, name: this.trans('td-closed-my-locations',205) },
                { value: -2, name: ""},
                { value: 5, name: this.trans('td-clients-and-prospects-open',205) },
                { value: 6, name: this.trans('td-applicants-and-flexworkers-open',205) },
                { value: -3, name: ""},          
                { value: 7, name: this.trans('td-all-actions',205) },
            ],
            alertClass: new AppAlerts(),
            user_locations: null,
            user_role: null,
            user_id: null,
            loading_download: false,
            user_offices: [],
            mIssueAdd: { show: false, args: {title: null, action_for: null, id:0, list_type:"", id_office_data_location: 0, location_name: null }, result: 0, return: null },
            refreshCheck: 0,
            loadingDone: 0,
            customColumns: ["selected", "status"]
        }
    },

    methods:{     

        getList: function() {
            this.loading_list = true;
            axios
                .post("issue/getList", {
                    filters: JSON.stringify(this.filters),
                    user_locations: JSON.stringify(this.user_locations),
                })
                .then(res => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.user_offices = res.data.user_offices
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        change(item){
            if(this.user_role < 3 || item.can_user_edit){
                if(Array.isArray(item)){
                    item = item[0]
                }

                this.mIssuesChange.args.id_issue_data_main = item.id;
                this.mIssuesChange.args.action_for = item.person_name;
                this.mIssuesChange.args.title = item.title;
                this.mIssuesChange.args.description = item.description;
                this.mIssuesChange.args.status = item.status;
                this.mIssuesChange.args.event_date = item.event_date;
                this.mIssuesChange.args.user_name = item.user_name
                this.mIssuesChange.args.id_user = item.user_id;
                this.mIssuesChange.args.issue_const_target = item.issue_const_target;
                this.mIssuesChange.args.user_locations = this.user_locations
                this.mIssuesChange.args.id_office_data_location = item.id_office_data_location;
                this.mIssuesChange.args.location_name = item.location_name;
                this.mIssuesChange.args.list_type = 'issue_list';
            
                this.mIssuesChange.show = true; 
            }
        },

        remove(item) {
            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("issue/remove", {
                            id_issue_data_main: item.id,
                            list_type: 'issue_list',
                            issue_const_target: item.issue_const_target,
                            filters: JSON.stringify(this.filters),
                            user_locations: JSON.stringify(this.user_locations),
                        })
                        .then(res => {
                            this.items = res.data.items;
                            this.filters.page = res.data.page;
                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                    };
             });  
        },

        setAsDone(id_issue_data_main){
            this.loadingDone = id_issue_data_main;
            this.loading_list = true;
            axios
                .post("issue/setAsDone", {
                    id_issue_data_main: id_issue_data_main,
                    list_type: "issue_list",
                    filters: JSON.stringify(this.filters),
                    user_locations: JSON.stringify(this.user_locations),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.loadingDone = 0;
                    this.loading_list = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);                   
                });
        },

        exportXLS(){
            this.loading_download = true;
            axios
                .post("issue/exportXLS", {
                    filters: JSON.stringify(this.filters),
                    user_locations: JSON.stringify(this.user_locations),
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Acties ' + moment().format('DD-MM-YYYY - HHmmss') +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_download = false;
                });
        },

        addIssue() {           
            if(this.user_offices.length == 1){
                this.mIssueAdd.args.id_office_data_location = this.user_offices[0].value;
                this.mIssueAdd.args.location_name = this.user_offices[0].name;
            }
            this.mIssueAdd.args.list_type = "issue_list";
            this.mIssueAdd.result = 0;
            this.mIssueAdd.show = true;
        },

        onCreateThis() { 

        this.getList();
       
        this.$watch('filters.page', function(newVal, oldVal) {
            if (!this.loading_list) {
                this.getList();
            }
        });

        this.$watch('items.refresh', function(newVal, oldVal) {
            if (newVal > 0 && !this.loading_list) {
                this.getList();
            }  
        });

        this.$watch('filters.list_type', function(newVal, oldVal) {
            if (!this.loading_list) {
                if(this.filters.list_type == 7){
                    this.fields[8].visible = true;
                    this.loading_list = true;
                    this.filters.period_date_from = moment().subtract(1, 'months').format('DD-MM-YYYY');
                    this.filters.period_date_to = moment().add(1, 'months').format('DD-MM-YYYY');
                }else{
                    this.filters.filtering.statuses.values = [];
                    this.fields[8].visible = false;
                }
                this.type = this.filters.list_type;
                this.getList();
            }
        });

        this.$watch('filters.sorting.changed', function(newVal, oldVal) {
            if (!this.loading_list) {
                this.getList();
            }
        });

        this.$watch('filters.changed', function(newVal, oldVal) {
            if (!this.loading_list) {
                this.getList();
            }
        });

        this.$watch('filters.filtering.user_names.changed', function(newVal, oldVal) {
            if (!this.loading_list) {
                this.getList();
            } 
        });

        this.$watch('filters.filtering.issue_const_targets.changed', function(newVal, woldVal) {
            if (!this.loading_list) {
                this.getList();
            } 
        });

        this.$watch('filters.filtering.statuses.changed', function(newVal, woldVal) {
            if (!this.loading_list) {
                this.getList();
            } 
        });

        this.$watch('filters.filtering.creators.changed', function(newVal, woldVal) {
            if (!this.loading_list) {
                this.getList();
            } 
        });

        this.$watch('filters.period_date_from', function(newVal, oldVal) {
            if (!this.loading_list) {
                if(this.filters.period_date_from == null || moment(this.filters.period_date_from,"DD-MM-YYYY").isValid()){

                    if(moment(this.filters.period_date_to,"DD-MM-YYYY").isBefore(moment(this.filters.period_date_from,"DD-MM-YYYY"))){
                        this.filters.period_date_to = null;
                    }

                    this.filters.disabledDatesRange = '(date <= new Date('+moment(this.filters.period_date_from,"DD-MM-YYYY")+'))';
                    this.getList();
                }else{
                    this.filters.period_date_from = null;
                }
            }
        });

        this.$watch('filters.period_date_to', function(newVal, oldVal) {
            if (!this.loading_list) {
                if(this.filters.period_date_to == null || moment(this.filters.period_date_to,"DD-MM-YYYY").isValid()){
                    this.getList();
                }else{
                    this.filters.period_date_to = null;
                }
            }
        });
      }
           
    },

};
</script>

<style>
.app-issues-status-item {
    top: 10px;
    bottom: 10px;
    left: 0;
    width: 4px;
    content: '';
    border-radius: 8px;
    background-color: white;
    height: 25px;
}

.app-issues-danger{
    background-color: #F04438;
}

.app-issues-success{
    background-color: #12b76a;
}
</style>