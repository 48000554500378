<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('td-change-action',205)">
        <ValidationObserver ref="form" >
            <app-row-left-label :label="trans('td-action-for',205)">
                <app-input v-model="cArgs.action_for" disabled />
            </app-row-left-label>

            <app-row-left-label :label="trans('td-title',205)">
                <app-input v-model="cArgs.title" validatorName="Titel" validatorRules="required" mask="lettersE9-65" />
            </app-row-left-label>
            
            <app-row-left-label :label="trans('td-description',205)">
                    <app-memo id="vi-description" rows="5" class="mt-2" v-model="cArgs.description" />
            </app-row-left-label>

            <app-row-left-label :label="trans('td-owner',205)" >
                    <app-select  v-model="user" :clearable="true" :disable="false" type="getUserSelectorList"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="location" type="getUserLocationsSimple" :disable="false" validatorName="Vestiging" validatorRules="required"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-to',182)">
                    <app-date  id="vi-event_date" v-model="cArgs.event_date" :disabledDatesPass="1" validatorRules="required|date-check|date-more-check" validatorName="Einddatum"/>
            </app-row-left-label>

        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="issueRemove()" style="margin-right:auto;" :disabled="loading_delete"  >
                <b-spinner class="mr-1" small v-if="loading_delete" />
                {{trans('remove',175)}}
            </app-button>

            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
   
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationObserver } from "vee-validate";
import AppAlerts from '@core/scripts/AppAlerts';
import { datecheck } from "@validations";

export default {

    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "filters"],

    created(){
            if(this.args.user_name != null  && this.args.user_name != ''){
                this.user = {name: this.args.user_name, value: this.args.id_user}
            }
            if(this.args.location_name != null){
               this.location = {name: this.args.location_name, value: this.args.id_office_data_location}
            }
            

    },

    watch: {
        "args.description": {
            handler: function() {             
                 if(this.args.description.length == 1){
                    this.cArgs.description = this.args.description.toUpperCase();
                 }   
            },
            deep: true,
        },
    },

    computed: {
        cArgs: {
        get() { return this.args },
        set() { this.$emit('update:args', false) }, 
        },
        cReturn: {
        get() { return this.return },
        set() { this.$emit('update:return', false) }, 
        },
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        }
    },

    data() {
        return {
            loading: false,
            loading_delete:false,
            user: null,
            location: null,
            parameters:{
                search:"",
                sort_by: "id",
                sort_desc: false,
                args:[],
            },
            alert_class: new AppAlerts(),

        };
    },

    methods: {
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("issue/change", {
                            id_issue_data_main: this.args.id_issue_data_main,
                            id: this.args.id,
                            id_user: this.user ? this.user.value : null,
                            title: this.args.title,
                            list_type: this.args.list_type,
                            event_date: this.args.event_date,
                            description: this.args.description,
                            filters: JSON.stringify(this.filters),
                            user_locations: JSON.stringify(this.args.user_locations),
                            id_office_data_location: this.location.value
                        })
                        .then((res) => {
                            
                            if(this.args.list_type == 'issue_list'){
                                this.cReturn.list = res.data.items.list;
                                this.cReturn.total = res.data.items.total;
                            }else{
                                this.cReturn.issues.list = res.data;
                            }
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            })
        },
        
        issueRemove() {
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loading_delete = true;
                axios
                    .post("issue/remove", {
                        id_issue_data_main: this.args.id_issue_data_main,
                        id: this.args.id,
                        list_type: this.args.list_type,
                        filters: JSON.stringify(this.filters),
                        user_locations: JSON.stringify(this.args.user_locations),
                    })
                    .then((res) => {
                         if(this.args.list_type == 'issue_list'){
                            this.cReturn.list = res.data.items.list;
                            this.cReturn.total = res.data.items.total;
                        }else{
                            this.cReturn.issues.list = res.data;
                        }
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.$emit("update:show", false);
                        this.loading_delete = false;
                    });
                };
             });  
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
